<script>
import RegionBase from './RegionBase'
import { southKoreaManifest } from '../ea-vgas-giveaway-manifest'

export default {
  extends: RegionBase,
  data() {
    return {
      regionId: 'south_korea',
      activeIntro: 'Giveaways for South Korea',
      gameList: southKoreaManifest
    }
  },
  metaInfo() {
    return {
      title: 'The Game Awards Giveaways - South Korea',
      titleTemplate: '%s | Surf Giveaways'
    }
  }
}
</script>
<style lang="scss" src="@/assets/themes/ea-vgas/scss/main.scss" />
